@font-face {
    font-family: "Bitter";
    src: url(../assets/fonts/Bitter-VariableFont_wght.ttf);
}

@font-face {
    font-family: "Roboto-condensed";
    src: url(../assets/fonts/RobotoCondensed-VariableFont_wght.ttf);
}

@media only screen and (max-width:500px) {
    header.header {
        width: 100%;
        height: 8rem;
        background: white;
    }

    div.container {
        width: 400px;
        height: auto;
        margin: auto;
    }

    ul.header-ul,
    div.header-icon-box {
        display: none;
    }

    div.side-bar-Icon-box {
        display: block;
    }

    div.start-box {
        background: #ffffff29;
        width: 100%;
        height: 47rem;
        background: url("../assets/imgs/students.jpg") no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
    }

    h1.start-text {
        padding: 28rem 0 3rem 0;
        color: white;
        font-size: 3.5rem;
    }

    div#aboute-us {
        width: 100%;
        height: 155rem;
        margin: 10rem 0;
    }

    div.aboute-text-card-box {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    div.aboute-text-box {
        width: 720px;
        height: 33rem;
    }

    div.aboute-info-box {
        margin: 1rem 0;
    }

    div.info-card-box {
        width: 390px;
        height: 5.5rem;
        margin: 3rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 10px 10px 0 #5b575733, 0 6px 20px 0 #5b595930;
        border-radius: 10px;
    }

    p.aboute-card-number {
        font-weight: bold;
        font-size: 30px;
        font-family: "Roboto-condensed";
        color: black;
        margin: 0 5rem 0 0;
        color: #0a29f2;
    }

    div#courses {
        width: 100%;
        height: 140rem;
        margin: 1rem auto;
    }

    h1.courses-text {
        width: 400px;
        font-weight: bold;
        font-size: 35px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.courses-box {
        width: 100%;
        height: 125rem;
        margin: 3.5rem auto;
        border-radius: 50px;
        background: white;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    div.modal {
        width: 380px;
        height: 45rem;
        background: white;
        margin: 5rem auto;
        border-radius: 50px;
    }

    div.variants-box,
    div.info-box {
        width: 20rem;
        height: 18rem;
        background: white;
        border-radius: 50px;
        margin: 1rem auto;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        overflow-y: scroll;
    }

    div.info-color-box {
        width: 20rem;
        height: 18rem;
        background: orange;
        border-radius: 50px;
        margin: 1rem auto;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        overflow-y: scroll;
    }

    p.modal-text {
        margin: 2rem 0 0 1.5rem;
        width: 290px;
        font-weight: bold;
        font-size: 35px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.variants-card {
        width: 18rem;
        height: 3.5rem;
        background: white;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.5rem auto;
    }

    div.modal-icon-box {
        width: 3rem;
        height: auto;
        padding: 1rem 0 0 20rem;
    }

    p.modal-info-title {
        font-weight: 600;
        font-size: 20px;
        font-family: "Roboto-condensed";
        margin: 1rem 0 0 3rem;
    }

    div#connection {
        width: 100%;
        height: 55rem;
    }

    form.connection-form {
        width: 400px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 3rem auto;
    }

    h1.connection-text {
        width: 395px;
        font-weight: bold;
        font-size: 35px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.validation-box {
        width: 23rem;
        height: 7rem;
        margin: auto;
    }

    select.connection-select {
        width: 23rem;
        height: 3.2rem;
        margin: 1rem auto 1rem auto;
        padding: 10px;
        border-radius: 10px;
        border: none;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        font-family: "Roboto-condensed";
        font-size: 20px;
    }

    textarea.connection-textarea {
        width: 380px;
        height: 10rem;
        border-radius: 10px;
        border: none;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        font-family: "Roboto-condensed";
        font-size: 20px;
        padding: 1remrem;
        color: #0004ff;
        margin: 0 auto;
    }

    footer.footer {
        width: 100%;
        height: 90rem;
        background: white;
    }

    div.logo-info-box {
        width: 100%;
        height: 80rem;
        border-top: 6px solid #0004ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    img.footer-logo,
    div.footer-info-box,
    iframe.ivaf_hup-location {
        width: 22rem;
        margin: 3rem auto;
    }
}
/*  */ /* */ /* */ /* */ /* */ /* */ /* */
@media only screen and (max-width:390px) {
    header.header {
        width: 100%;
        height: 8rem;
        background: white;
    }

    div.container {
        width: 375px;
        height: auto;
        margin: auto;
    }

    ul.header-ul,
    div.header-icon-box {
        display: none;
    }

    div.side-bar-Icon-box {
        display: block;
    }

    div.start-box {
        background: #ffffff29;
        width: 100%;
        height: 47rem;
        background: url("../assets/imgs/students.jpg") no-repeat;
        background-position: center;
        background-size: cover;
        text-align: center;
    }

    h1.start-text {
        padding: 28rem 0 3rem 0;
        color: white;
        font-size: 3.5rem;
    }

    div#aboute-us {
        width: 100%;
        height: 155rem;
        margin: 10rem 0;
    }

    div.aboute-text-card-box {
        display: flex;
        align-items: start;
        justify-content: space-between;
        flex-wrap: wrap;

    }

    div.aboute-text-box {
        width: 720px;
        height: 33rem;
    }

    div.aboute-info-box {
        margin: 1rem 0;
    }

    div.info-card-box {
        width: 355px;
        height: 5.5rem;
        margin: 3rem auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-shadow: 0 10px 10px 0 #5b575733, 0 6px 20px 0 #5b595930;
        border-radius: 10px;
    }

    p.aboute-card-number {
        font-weight: bold;
        font-size: 30px;
        font-family: "Roboto-condensed";
        color: black;
        margin: 0 5rem 0 0;
        color: #0a29f2;
    }

    div.slide,
    div.teachers-box {
        width: 19rem;
        height: 38.9rem;
        margin: 4.8rem auto;
        border-bottom-left-radius: 25px;
        border-bottom-right-radius: 25px;
    }

    img.slide-img {
        width: 304px;
        height: 570px;
        margin: 0 1rem;
    }

    div#courses {
        width: 100%;
        height: 140rem;
        margin: 1rem auto;
    }

    h1.courses-text {
        width: 375px;
        font-weight: bold;
        font-size: 35px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.courses-box {
        width: 100%;
        height: 125rem;
        margin: 3.5rem auto;
        border-radius: 50px;
        background: white;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        display: flex;
        align-items: center;
        justify-content: space-around;
        flex-wrap: wrap;
    }

    div.modal {
        width: 340px;
        height: 39rem;
        background: white;
        margin: 10px auto;
        border-radius: 50px;
    }

    div.variants-box,
    div.info-box {
        width: 20rem;
        height: 15.8rem;
        background: white;
        border-radius: 40px;
        margin: 1rem auto;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        overflow-y: scroll;
    }

    div.info-color-box {
        width: 20rem;
        height: 15.8rem;
        background: orange;
        border-radius: 40px;
        margin: 1rem auto;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        overflow-y: scroll;
    }

    p.modal-text {
        margin: 2rem 0 0 1.5rem;
        width: 290px;
        font-weight: bold;
        font-size: 33px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.variants-card {
        width: 18rem;
        height: 3.5rem;
        background: white;
        border-radius: 20px;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1.5rem auto;
    }

    div.modal-icon-box {
        width: 3rem;
        height: auto;
        padding: 1rem 0 0 18rem;
    }

    p.modal-info-title {
        font-weight: 600;
        font-size: 20px;
        font-family: "Roboto-condensed";
        margin: 1rem 0 0 3rem;
    }

    div#connection {
        width: 100%;
        height: 55rem;
    }

    form.connection-form {
        width: 365px;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 3rem auto;
    }

    h1.connection-text {
        width: 375px;
        font-weight: bold;
        font-size: 35px;
        font-family: "Roboto-condensed";
        color: #0a29f2;
        border-bottom: 7px solid #0a29f2;
    }

    div.validation-box {
        width: 23rem;
        height: 7rem;
        margin: auto;
    }

    select.connection-select {
        width: 22.8rem;
        height: 3.2rem;
        margin: 1rem auto 1rem auto;
        padding: 10px;
        border-radius: 10px;
        border: none;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        font-family: "Roboto-condensed";
        font-size: 20px;
    }

    textarea.connection-textarea {
        width: 365px;
        height: 10rem;
        border-radius: 10px;
        border: none;
        box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
        font-family: "Roboto-condensed";
        font-size: 20px;
        padding: 1remrem;
        color: #0004ff;
        margin: 0 auto;
    }

    footer.footer {
        width: 100%;
        height: 90rem;
        background: white;
    }

    div.logo-info-box {
        width: 100%;
        height: 80rem;
        border-top: 6px solid #0004ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    img.footer-logo,
    div.footer-info-box,
    iframe.ivaf_hup-location {
        width: 22rem;
        margin: 3rem auto;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

body {
    background: white;
}

header {
    width: 100%;
    height: 8rem;
}

.container {
    width: 1440px;
    height: auto;
    margin: auto;
}

.header-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.1rem 0;
}

.site-logo {
    width: 15rem;
    height:6rem;
    border-radius: 15px;
}
.header-ul {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.header-link {
    font-weight: 500;
    font-size: 18px;
    font-family: "Roboto-condensed";
    color: black;
}

.side-bar-Icon-box {
    display: none;
}

.header-icon,
.x-icon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    transition: 1s linear;
}

.x-icon:hover {
    transform: rotate(90deg);
}

.side-bar-icon {
    width: 2rem;
    height: 2rem;
}

.side-bar-box {
    z-index: 9999999;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: #0d0c0cca;
    transition: 60s linear;
}

.side-bar {
    width: 20rem;
    height: 100vh;
    background: white;
    box-shadow: 0 10px 10px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 10px 0 0 10px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    left: end;
}

.side-bar-components {
    width: 100%;
    height: auto;
    text-align: center;
}

.side-bar-link {
    display: block;
    margin: 3rem 0;
    font-weight: 500;
    font-size: 25px;
    font-family: "Roboto-condensed";
    color: black;

}

.side-bar-icon-box {
    width: 15rem;
    height: auto;
    margin: 15rem auto;
    border-top: 3px solid #0a29f2;
}

.side-bar-icon {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
    margin-top: 0.3rem;
}

.header-link:hover,
.header-icon:hover,
.side-bar-icon:hover,
.side-bar-link:hover {
    color: #0a29f2;
    transition: 0.2s linear;
}

.start-box {
    background: #ffffff29;
    width: 100%;
    height: 55rem;
    background: url("../assets/imgs/students.jpg") no-repeat;
    background-position: center;
    background-size: cover;
    text-align: center;
}

.start-text {
    padding: 40rem 0 3rem 0;
    color: white;
    font-size: 5rem;
    text-shadow: 12px 15px 10px #0b0b0b;
}

.start-button {
    font-family: "Roboto-condensed";
    padding: 13px 5rem;
    border-radius: 10px;
    background: #010101fe;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    border: none;
}

.start-button:hover {
    background: #0a29f2;
    color: white;
    transition: 0.1s linear;
}

.start-button:active {
    border: 2px solid white;
    transition: 0.1s linear;
}

#aboute-us {
    width: 100%;
    height: 70rem;
    margin: 10rem 0;
}

.aboute-text-card-box {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.aboute-text-box,
.aboute-info-box {
    width: 720px;
    height: 20rem;
}

.aboute-text {
    font-weight: bold;
    font-size: 35px;
    font-family: "Roboto-condensed";
    color: #0a29f2;
    border-bottom: 7px solid #0a29f2;
}

.aboute-title {
    font-weight: 500;
    font-size: 25px;
    font-family: "Roboto-condensed";
    color: black;
    margin: 2rem;
}

.info-card-box {
    width: 650px;
    height: 5.5rem;
    margin: 3rem auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 10px 10px 0 #5b575733, 0 6px 20px 0 #5b595930;
    border-radius: 10px;
    background: white;
}

.aboute-card-img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    margin: 0 1rem;
    box-shadow: 0 10px 10px 0 #00000033, 0 6px 20px 0 #00000030;
}

.aboute-card-text {
    font-weight: 500;
    font-size: 25px;
    font-family: "Roboto-condensed";
    color: black;
    margin: 0 5rem 0 0;
}

.aboute-card-number {
    font-weight: bold;
    font-size: 40px;
    font-family: "Roboto-condensed";
    color: black;
    margin: 0 5rem 0 0;
    color: #0a29f2;
}

.slide-teacher-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.iphone-border-box {
    width: 25rem;
    height: 47rem;
    margin: 1rem auto;
    background: url(../assets/imgs/iphone.jpg) no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 70px;
}

.slide,
.teachers-box {
    width: 20rem;
    height: 41.9rem;
    margin: 3.5rem auto;
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
}

.teachers-box {
    overflow-y: scroll;
}

.teacher-card {
    width: 17rem;
    height: 19.5rem;
    margin: 1rem auto;
    border-radius: 10px;
    background: white;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
}

.teachers-img {
    width: 13.3rem;
    height: 14.5rem;
    margin: 10px 30px;
    border-radius: 10px;
}

.teachers-name,
.teachers-science {
    text-align: center;
    color: #0a29f2;
    font-family: "Bitter";
}

#courses {
    width: 100%;
    height: 65rem;
    margin: 1rem auto;
}

.courses-text {
    width: 720px;
    font-weight: bold;
    font-size: 35px;
    font-family: "Roboto-condensed";
    color: #0a29f2;
    border-bottom: 7px solid #0a29f2;
}

.courses-box {
    width: 100%;
    height: 55rem;
    margin: 3.5rem auto;
    background: white;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 50px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.courses-card {
    width: 22rem;
    height: 13rem;
    margin: 2rem;
    border-radius: 10px;
    background: white;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    transition: 0.5s linear;

}

.courses-card:hover {
    transform: scale(1.1);
}

.courses-name {
    width: 18rem;
    font-family: "Bitter";
    color: #ffffff;
    font-weight: bold;
    text-shadow: 10px 10px 1px #090808fa;
    font-size: 28.5px;
    margin: 10rem 1rem auto;
}

.modal-box {
    z-index: 999999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #0b0b0bea;
}

.modal-icon-box {
    width: 3rem;
    height: auto;
    padding: 1rem 0 0 76rem;
}

.modal {
    width: 80rem;
    height: 45rem;
    background: white;
    margin: 10rem auto;
    border-radius: 50px;
}

.variants-info-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
}

.variants-box,
.info-box {
    width: 30rem;
    height: 37rem;
    background: white;
    border-radius: 50px;
    margin: 1rem 0;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
}

.info-color-box {
    width: 30rem;
    height: 37rem;
    background: orange;
    border-radius: 50px;
    margin: 1rem 0;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    color: #f1f0f0;
    overflow-y: scroll;
}

.modal-text {
    margin: 2rem 0 0 1.5rem;
    width: 400px;
    font-weight: bold;
    font-size: 35px;
    font-family: "Roboto-condensed";
    color: #0a29f2;
    border-bottom: 7px solid #0a29f2;
}

.variants-card {
    width: 25rem;
    height: 3.5rem;
    background: white;
    border-radius: 20px;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 1.5rem auto;
}

.variants-text {
    font-weight: bold;
    font-size: 20px;
    font-family: "Bitter";
    margin: 0 0 0 1rem;
}

.modal-info-title {
    font-weight: 600;
    font-size: 23px;
    font-family: "Roboto-condensed";
    margin: 1rem 0 0 3rem;
}

#connection {
    width: 100%;
    height: 44rem;
}

.connection-text {
    width: 720px;
    font-weight: bold;
    font-size: 35px;
    font-family: "Roboto-condensed";
    color: #0a29f2;
    border-bottom: 7px solid #0a29f2;
}

.connection-form {
    width: 75rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 3rem auto;
}

.validation-box {
    width: 36rem;
    height: 7rem;
    margin: auto;
}

.connection-inputs {
    border-radius: 10px;
    padding: 1.5rem;
    width: 100%;
    height: 1rem;
    border: none;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    margin: 1rem 0;
    font-family: "Roboto-condensed";
    font-size: 20px;
    color: #0004ff;
}

.connection-inputs:focus {
    border: 2.5px solid #0004ff;
}

.error-text {
    color: red;
    font-family: "Roboto-condensed";
    font-size: 20px;
}

.connection-select {
    width: 36.5rem;
    height: 3.2rem;
    margin: 0.8rem auto;
    padding: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    font-family: "Roboto-condensed";
    font-size: 20px;
}

.connection-textarea {
    width: 100%;
    height: 10rem;
    border-radius: 10px;
    border: none;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    font-family: "Roboto-condensed";
    font-size: 20px;
    padding: 1rem;
    color: #0004ff;
    resize: none;
}

.submit-button {
    border: none;
    background: #0b0b0b;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 10px;
    padding: 10px 3rem;
    margin: 3rem auto;
    color: white;
    font-size: 20px;
}

.submit-button:hover {
    background: #0004ff;
}

.submit-button:active {
    border: 3px solid white;
}

footer {
    width: 100%;
    height: 33rem;
    background: white;
}

.logo-info-box {
    width: 100%;
    height: 25rem;
    border-top: 6px solid #0004ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.footer-logo {
    width: 20rem;
    height: 20rem;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 50px;
}

.footer-info-box {
    width: 25rem;
    height: 20rem;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 50px;
}

.footer-info-text {
    display: flex;
    align-items: center;
    font-family: "Bitter";
    font-weight: bold;
    font-size: 18px;
    margin: 3rem 1rem;
}

.footer-info-icon {
    width: 1.5rem;
    height: 1.5rem;
    color: #0004ff;
    margin: 0 1rem;
}

.ivaf_hup-location {
    width: 40rem;
    height: 20rem;
    box-shadow: 0 4px 8px 0 #00000033, 0 6px 20px 0 #00000030;
    border-radius: 50px;
    border: none;
}

.end-text {
    width: 50%;
    font-family: "Bitter";
    font-weight: bold;
    font-size: 26px;
    margin: 2.5rem auto;
    text-align: center;
    border-top: 6px solid #0004ff;
}